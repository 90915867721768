<template>
  <div style="margin-top: 100px; margin-bottom: 120px">
    <b-container>
      <h2 class="font-weight-bolder text-colorBlue">Hotels</h2>

      <b-row class="d-flex justify-content-center">
        <b-col
          md="6"
          lg="4"
          v-for="(hotel, index) in hotels"
          :key="index"
          class="mb-4 d-flex flex-column justify-content-between"
          :class="{ 'bg-colorGrey ': isHovered === index }"
          style="border-radius: 20px"
          @mouseover="isHovered = index"
          @mouseleave="isHovered = null"
        >
          <div class="">
            <img :src="hotel.image" class="img-fluid rounded p-0 mt-1" />
          </div>

          <div class="text-center">
            <h3 class="font-weight-bolder text-colorBlue pt-1">
              {{ hotel.name }} {{ hotel.location }}
            </h3>
            <ol class="text-dark font-weight-bolder">
              <li
                v-for="(room, roomIndex) in hotel.hotel_rooms"
                :key="roomIndex"
              >
                <div class="d-flex justify-content-between">
                  <div>{{ room.name }}</div>
                  <div class="pr-2 text-colorGreen font-weight-bolder">
                    {{ room.amount }}$
                  </div>
                  <div class="pr-2">
                    <b-form-group v-slot="{ ariaDescribedby }">
                      <b-form-radio
                        :id="`hotel_${index}-room_${roomIndex}`"
                        v-model="selectedRooms[index]"
                        :aria-describedby="ariaDescribedby"
                        :name="`hotel_${index}`"
                        :value="room"
                        >check to select</b-form-radio
                      >
                    </b-form-group>
                  </div>
                </div>
              </li>
            </ol>
            <!-- <div class="mb-1 font-weight-bolder text-colorGreen">
              Selected:
              <span class="font-weight-bold text-dark">{{
                selectedRooms[index] && selectedRooms[index].name
              }}</span>
            </div> -->
          </div>

          <!-- <div class="text-center">
            <b-button
              block
              rounded
              :variant="isInCart(hotel) ? 'colorBlue' : 'colorGreen'"
              size="sm"
              class="font-weight-bolder mb-sm-1"
              @click="handleCartAction(hotel, selectedRooms[index])"
            >
              {{ isInCart(hotel) ? 'View Cart' : 'Add to Cart' }}
            </b-button>
          </div> -->
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import util from "@/util.js";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    ToastificationContent,
  },
  mixins: [util],
  data() {
    return {
      selected: "",
      isHovered: null,
      hotels: [],
      selectedRooms: {},
    };
  },
  async mounted() {
    try {
      const res = await this.getHotelsPublic({});
      if (res.status === 200) {
        this.hotels = res.data;
        for (let i = 0; i < this.hotels.length; i++) {
          const hotel = this.hotels[i];
          this.$set(this.selectedRooms, i, null);
          hotel.hotel_rooms = hotel.hotel_rooms.map((room) => {
            return { ...room };
          });
        }
      }
    } catch (error) {
      this.displayError(error);
    }
  },
  computed: {
    ...mapState({
      cart: (state) => state.appData.cart,
    }),
  },
  methods: {
    ...mapActions({
      getHotelsPublic: "appData/getHotelsPublic",
      addToCart: "appData/addToCart",
      removeFromCart: "appData.removeFromCart",
    }),
    addCart(hotel, selectedRoom) {
      const cartItemId = `${hotel.id}_${this.cartItemType.HOTEL}`;
      const isInCart = this.cart.some((cartItem) => cartItem.id === cartItemId);

      if (!isInCart && selectedRoom) {
        this.addToCart({
          id: cartItemId,
          name: hotel.name,
          hotelId: hotel.id,
          roomName: selectedRoom.name,
          itemId: selectedRoom.id,
          price: selectedRoom.amount,
          type: this.cartItemType.HOTEL,
          quantity: 1,
          numberOfDays: 1,
        });
        this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: "Item added to cart successfully",
              variant: "success",
              icon: "BellIcon",
            },
          },
          {
            position: "top-right",
          }
        );
      } else if (!selectedRoom) {
        this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: "Please select a room to add to your cart",
              variant: "danger",
              icon: "BellIcon",
            },
          },
          {
            position: "top-right",
          }
        );
      }
    },
    handleCartAction(hotel, selectedRoom) {
      if (this.isInCart(hotel)) {
        this.$router.push({ name: "Cart" });
      } else {
        this.addCart(hotel, selectedRoom);
      }
    },
    isInCart(hotel) {
      const cartItemId = `${hotel.id}_${this.cartItemType.HOTEL}`;
      return this.cart.some((cartItem) => cartItem.id === cartItemId);
    },
  },
};
</script>

<style></style>
