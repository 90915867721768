<template>
  <div>
    <Topbar />
    <Navbar />
    <Banner />
    <Hotel />
    <Footer />
    <CartButton />
    <!-- <SocialChat icon :attendants="attendants">
      <p slot="header" class="font-weight-bolder">
        Click on one of our attendants below to chat on WhatsApp.
      </p>
      <template v-slot:button>
        <img
          src="https://raw.githubusercontent.com/ktquez/vue-social-chat/master/src/icons/whatsapp.svg"
          alt="icon whatsapp"
          aria-hidden="true"
        />
      </template>
      <small class="font-weight-bolder" slot="footer">Open: 24 hrs </small>
    </SocialChat> -->
  </div>
</template>

<script>
import Topbar from "@/components/home/Topbar.vue";
import Navbar from "@/components/home/Navbar.vue";
import Banner from "@/components/hotel/Banner.vue";
import Hotel from "@/components/hotel/Hotel.vue";
import Footer from "@/components/home/Footer.vue";
import CartButton from "@/components/floatingButton/CartButton.vue";
import { SocialChat } from "vue-social-chat";

export default {
  components: {
    Topbar,
    Navbar,
    Banner,
    Hotel,
    Footer,
    SocialChat,
    CartButton,
  },
  data() {
    return {
      attendants: [
        {
          app: "whatsapp",
          label: "Medask Tours",
          name: "Customer Support",
          number: "923196048487",
          avatar: {
            src: "https://avatars0.githubusercontent.com/u/8084606?s=460&u=20b6499a416cf7129a18e5c168cf387e159edb1a&v=4",
            alt: "Alan Ktquez avatar",
          },
        },
      ],
    };
  },
};
</script>

<style></style>
